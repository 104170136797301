import { isProductionEnv } from '@config/configEnv';

/**
 * For commercial purpose
 */
export const CONTACT_EMAIL = 'contact@livv.eu';
/**
 * For technical issues
 */
export const SUPPORT_EMAIL = 'support@livv.eu';
export const NOREPLY_EMAIL = 'noreply@livv.eu';
export const APP_URL = isProductionEnv() ? 'app.livv.eu' : 'preprod.app.livv.eu';
export const LANDING_URL = 'livv.eu';
export const APP_NAME = 'Livv';
