import dynamic from 'next/dynamic';
import { FC } from 'react';

const NavBar = dynamic(() => import('@components/NavBar'), {
    ssr: true,
});

interface DynamicNavBarProps {
    clientOs?: string;
}

const DynamicNavBar: FC<DynamicNavBarProps> = ({ clientOs }) => <NavBar clientOs={clientOs} />;

export default DynamicNavBar;
