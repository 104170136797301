var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"69b76630037dbfedbeab854accfabde2c13f08dc"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { ENVIRONMENT, SENTRY_DSN, shouldIgnoreEvent, shouldIgnoreException } from './sentry.common';

Sentry.init({
    beforeSend(event, hint) {
        if (typeof navigator !== 'undefined') {
            const { userAgent } = navigator;
            if (shouldIgnoreEvent(userAgent)) {
                // Ignore this event
                return null;
            }

            const exception = hint.originalException;

            if (shouldIgnoreException(exception)) {
                // Ignore this exception
                return null;
            }
        }

        return event;
    },
    dsn: SENTRY_DSN,
    enabled: process.env.NODE_ENV === 'production',
    environment: ENVIRONMENT,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    tunnel: '/api/tunnel',
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});
