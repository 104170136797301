import { GetDecisionAssociatedDocuments } from '@livv/models';
import { CreateUser, SendOnboardingEmail } from '@livv/models/firebaseFunctions';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@config/firebase/client';

export * from './generateDocumentPdf';

export const createUser = httpsCallable<CreateUser['input'], CreateUser['output']>(
    functions,
    'createUser',
);

export const userAuth = httpsCallable(functions, 'auth');

export const generateDocumentPdf = httpsCallable(functions, 'generateDocumentPdf');

export const getDecisionAssociatedDocuments = httpsCallable<
    GetDecisionAssociatedDocuments['input'],
    GetDecisionAssociatedDocuments['output']
>(functions, 'getDecisionAssociatedDocuments');

export const getDecisionAnalyses = httpsCallable(functions, 'getDecisionAnalyses');

export const sendOnboardingEmail = httpsCallable<
    SendOnboardingEmail['input'],
    SendOnboardingEmail['output']
>(functions, 'sendOnboardingEmail');

export const slack = {
    sendNotification: httpsCallable(functions, 'slackSendNotification'),
};

export const searchableRecords = {
    requestKey: httpsCallable(functions, 'searchableRecordsRequestKey'),
};
